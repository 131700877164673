var Main = {
    resources: null,
    hasTransformedLeadsiusForNewsletter: false,
    hasTransformedLeadsiusForBlueBox: false,
    clickEvent: string = null,

    /**
     * Init function
     * @returns {} 
     */
    init: function () {
        var self = this;

        // Set click event depending on touch device or not
        self.clickEvent = Modernizr.touch ? "touchstart" : "click";

        window.addEventListener("DOMContentLoaded",
            function () {

                self.resources = JSON.parse($("#resources").val());
                if (!Modernizr.svgasimg) {
                    // Fallback to png for non-SVG clients
                    $("img[src$='.svg']").each(function (index, value) {
                        var $img = $(value);
                        $img.attr("src", $img.attr("data-svg-fallback"));
                    });
                }

                // Hide newsletter agreement
                $(".close-accept-terms").on(self.clickEvent, function () {
                    $(".accept-terms-dialog-wrapper").hide();
                    return false;
                });

                // Show newsletter agreement
                $(".show-accept-terms").on(self.clickEvent, function (e) {
                    var $parent = $(e.currentTarget).closest($(".newsletter-form"));

                    if (!$parent.length)
                        $parent = $(e.currentTarget).closest($(".blueblock-content"));

                    $(".accept-terms-dialog-wrapper", $parent ).show();
                    return false;
                });

                // Show or hide menu on icon click
                $(".icon-hamburger, .close-menu").on(self.clickEvent, function () {
                    $(".navlist-hamburger").toggleClass("active");
                    return false;
                });

                // Show or hide menu on icon click
                $(".expand").on(self.clickEvent, function (e) {

                    var $parentListItem = $(e.currentTarget).closest("li");
                    $(".navlist-hamburger li").not($parentListItem).removeClass("expanded");
                    $parentListItem.toggleClass("expanded");
                    return false;
                });

                // Show or hide contactinfo
                $(".contact-info-click-area").on("mouseover", function (e) {
                    $(".contact-info").addClass("open");

                    return false;
                });

                // Show contactinfo on click
                $(".contact-info-click-area").on(self.clickEvent, function (e) {
                    $(".contact-info").addClass("open");

                    return false;
                });

                // Hide contactinfo
                $(".contact-info").on("mouseleave", function (e) {
                    $(".contact-info").removeClass("open");

                    return false;
                });

                // Scroll top on click
                $(".scroll-top-button").on(self.clickEvent, function () {
                    $("html, body").animate({ scrollTop: "0" });;
                    return false;
                });

                $(".blueblock-content").on("DOMNodeInserted",
                    function (e) {
                        if (!self.hasTransformedLeadsiusForBlueBox) {

                            self.hasTransformedLeadsiusForBlueBox = true;
                            $(".ls-form-group label", ".blueblock-content").each(function(index, element) {
                                var $label = $(element);
                                if (!$label.parent().hasClass("checkbox") && !$label.next().hasClass("parent-checkbox")) {
                                    // Remove label
                                    $label.remove();
                                } else {
                                    // Move link inside of checkbox wrapper
                                    $label.append($(".show-accept-terms", ".blueblock-content"));
                                }
                            });
                        }
                    });

                $(".newsletter-field").on("DOMNodeInserted",
                    function (e) {
                        if (!self.hasTransformedLeadsiusForNewsletter) {

                            self.hasTransformedLeadsiusForNewsletter = true;

                            $(".ls-form-group label", ".newsletter-field").each(function (index, element) {
                                var $label = $(element);
                                if (!$label.parent().hasClass("checkbox")) {
                                    // Remove label
                                    $label.remove();
                                } else {
                                    // Move link inside of checkbox wrapper
                                    $label.append($(".show-accept-terms", ".newsletter-field"));
                                }
                            });


                            // Change classnames of input
                            $(".newsletter-field input[type=text]").addClass("newsletter-input")
                                .addClass("input-field");

                            var $styleSheets = $("head link");

                            // Remove leadsius style from head
                            $styleSheets.each(function (index, element) {
                                if ($(element).attr("href").indexOf("leadsius") !== -1) {
                                    $(element).remove();
                                }
                            });

                            // Change classnames of button
                            $(".newsletter-field button").addClass("button").addClass("blue");
                        }
                    });

                // Show or hide error
                $("#acceptNewsletterTerms").on(self.clickEvent, function () {
                    if (!$("#acceptNewsletterTerms").is(":checked")) {
                        $(".accept-newsletter-terms-wrapper").addClass("error");
                    } else {
                        $(".accept-newsletter-terms-wrapper").removeClass("error");
                    }
                });

                // Show features
                $(".show-features").on(self.clickEvent, function (e) {
                    $(e.currentTarget).closest(".buy-content").find(".buy-list").addClass("show-all");
                    $(e.currentTarget).closest(".buy-content").find(".hide-features").toggleClass("hide");
                    $(e.currentTarget).toggleClass("hide");
                    return false;
                });

                // Show features
                $(".hide-features").on(self.clickEvent, function (e) {
                    $(e.currentTarget).closest(".buy-content").find(".buy-list").removeClass("show-all");
                    $(e.currentTarget).closest(".buy-content").find(".show-features").toggleClass("hide");
                    $(e.currentTarget).toggleClass("hide");
                    return false;
                });

                // Hide hamburger meny on click outside
                $("html").on("touchstart click", function (e) {
                    
                    if ($(e.target).is("a, a *"))
                        return true;

                    if (!$(e.target).closest(".contact-info").length) {
                        $(".contact-info").removeClass("open");
                    }

                    if (!$(e.target).closest(".hamburger-menu").length) {

                        $(".navlist-hamburger").removeClass("active");
                        return true;
                    } else {
                        return false;
                    }
                });

                self.renderAnimatedText();

                // Add scroll class to header then scroll
                $(window).scroll(function () {
                    self.checkScroll();
                });

                // Add lazy loading
                var lazyImages = document.querySelectorAll(".lazy");

                self.addLazyLoad(lazyImages);
            });
    },

    /**
     * Check if user scrolled
     * @returns {} 
     */
    checkScroll: function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 1) {
            $("body").addClass("scroll");
        } else {
            $("body").removeClass("scroll");
        }
    },

    /**
     * Render animated text
     * @returns {} 
     */
    renderAnimatedText: function () {
        var self = this;

        if ($("#animatedHeaderText").length) {
            var sourceText = $("#animatedHeaderText").first().data("text-source");

            var lastTime = 0;
            for (var i = 0; i < sourceText.length; i++) {
                lastTime += self.getRandom(1, 4) * 30;
                (function (char, time) {
                    setTimeout(function () {
                        document.getElementById("animatedHeaderText").innerHTML += char;
                    },
                        time);
                })(sourceText[i], lastTime);
            }
        }
    },

    /**
     * Get random
     * @param {} min 
     * @param {} max 
     * @returns {} 
     */
    getRandom: function (min, max) {
        return Math.random() * (max - min) + min;
    },

    /**
     * Add lazy load 
     * @param
     */
    addLazyLoad: function(images) {

        //console.log("test");

        if (!window.navigator.userAgent.indexOf("MSIE ")) {

            lazyload(images);

        } else {

            //console.log(images);

            for (var i = 0; i < images.length; i++) {
                images[i].setAttribute("src", images[i].getAttribute("data-src"));
            }
        }
    }
}
Main.init();